<!--
 * @Author: yuwenwen
 * @Date: 2022-11-30 16:07:05
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-09 16:08:14
 * @FilePath: \nc-funeral-mobile\src\views\obituary\template\templateRefined.vue
-->
<template>
  <div class="template-details-page">
    <div class="template-2">
      <!-- 头部 -->
      <div class="header">
        <!-- 头像 -->
        <div class="avatar">
          <img class="avatar-icon" v-if="templateInfo.deadImg" :src="templateInfo.deadImg" />
          <img class="avatar-icon" v-if="!templateInfo.deadImg"
            src="../../../assets/images/obituary/template1/default-avatar.png" />
          <div class="avatar-left-d"></div>
          <div class="avatar-right-d"></div>
        </div>
        <!-- 标题 -->
        <div class="obituary-title">
          {{ templateInfo.deadName }} {{ templateInfo.title }}
        </div>
      </div>
      <!-- 邀请内容 -->
      <div class="mt-150-m">
        <div class="title-bg">邀请内文</div>
        <div class="content-box temp1-content-border">
          <div>
            我们挚爱的{{ templateInfo.deadName }}，已经在{{
              templateInfo.deadDay
            }}{{ templateInfo.deadTime }}辞世往生，享寿{{
  templateInfo.deadAge
}}岁。<br /><br />
            谨择于{{ templateInfo.ceremonyDay }}
            {{ templateInfo.ceremonyTime }}于{{
              templateInfo.celemonyPlace
            }}行告别仪式，敬邀诸位亲友莅临告别致意。
          </div>

          <div class="relations">{{ templateInfo.inviter }} 叩谢</div>
          <div class="details-info">日期：{{ templateInfo.ceremonyDay }}</div>
          <div class="details-info">时间：{{ templateInfo.ceremonyTime }}</div>
          <div class="details-info">地点：{{ templateInfo.ceremonyPlace }}</div>
          <div class="details-info">
            地址：{{ templateInfo.ceremonyAddress }}
          </div>
          <div class="details-info">电话：{{ templateInfo.contactNumber }}</div>
        </div>
      </div>

      <!-- 仪式地图 -->
      <div>
        <div class="title-bg mt-28 mb-19">仪式地图</div>
        <div class="content-box temp1-content-border">
          <div id="navi-container" class="navip-container"></div>
          <div class="details-info">南昌市殡仪馆</div>
          <div class="details-info">
            地址：江西省南昌市新建区松鹤路723号
          </div>
          <div class="details-info">电话：400-000-1448</div>
        </div>
      </div>

      <!-- 讣告留言 -->
      <div>
        <div class="title-bg mt-28 mb-19">讣告留言</div>
        <div class="content-box temp1-content-border">
          <div class="leave-message-entry" @click="previewId ? toLeaveMessage() : ''">
            写留言
          </div>
          <!-- 留言列表 -->
          <div v-if="messageList.length > 0">
            <div v-for="(item, index) in messageList" :key="index" class="leave-message-item">
              <div>
                <img class="avatar-box" :src="item.avatar" />
              </div>
              <div class="message-content">
                <div class="user-name">
                  {{ item.username }}<span class="time">{{ item.createTime }}</span>
                </div>
                <div>
                  {{ item.content }}
                </div>
              </div>
            </div>
            <div class="Pagination">
              <van-pagination v-model="Pagination.currentPage" :page-count="Pagination.pageCount"
                :items-per-page="Pagination.itemsPerPage" mode="simple" @change="pageChange">
                <template #prev-text>
                  <van-icon name="arrow-left" />
                </template>
                <template #next-text>
                  <van-icon name="arrow" />
                </template>
              </van-pagination>
            </div>
          </div>
          <div v-else style="text-align:center;margin-top:10px">暂无留言~</div>
        </div>
      </div>

      <!-- 讯息分享 -->
      <div class="title-bg mt-28 mb-19">讯息分享</div>

      <!-- 使用模板按钮 -->
      <div v-if="!isPreview">
        <div v-if="type != 'preview'" class="use-btn btn-color" @click="toAddContent">
          使用模版
        </div>
        <!-- 上一步，保存按钮 -->
        <div v-if="type == 'preview'" class="mt-28" style="display: flex; justify-content: space-between">
          <div class="save-btn btn-color" @click="back">上一步</div>
          <div class="save-btn btn-color" @click="saveData">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import template from "./template.js";
export default {
  name: "TemplateElegant",
  mixins: [template],
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/obituary.less";
</style>